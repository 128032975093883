import Intercom from "@intercom/messenger-js-sdk";
import { INTERCOM_APP_ID } from "../config/env";

export const loadPlausible = () => {
  if (!document.querySelector('script[data-domain="zevoy.com"]')) {
    const s = document.createElement('script');
    s.defer = true;
    s.setAttribute('data-domain', 'zevoy.com');
    s.src = 'https://plausible.io/js/script.js';
    document.head.appendChild(s);
  }
}

export const loadHubspot = () => {
  if (!document.getElementById('hs-script-loader')) {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.id = 'hs-script-loader';
    s.async = true;
    s.defer = true;
    s.src = '//js-eu1.hs-scripts.com/139515614.js';
    document.body.appendChild(s);
  }
}


export const loadMatomo = () => {
    if (!(window as any)._paq) {
      var _paq = (window as any)._paq = (window as any)._paq || [];
      _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
      _paq.push(["setCookieDomain", "*.zevoy.com"]);
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      var u="https://zevoy.matomo.cloud/";
      _paq.push(['setTrackerUrl', u+'matomo.php']);
      _paq.push(['setSiteId', '1']);
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.async=true; g.src='https://cdn.matomo.cloud/zevoy.matomo.cloud/matomo.js'; 
      s?.parentNode?.insertBefore(g,s);
    }
  }

  export const loadIntercom = () => {
    if (INTERCOM_APP_ID) {
      Intercom({
        app_id: INTERCOM_APP_ID,
        region: 'eu',
      })
    }
  }